import { useState } from 'react'
import styled from 'styled-components'
import { space, layout } from 'styled-system'
import { AiOutlineArrowDown } from 'react-icons/ai'
import { AiOutlineArrowUp } from 'react-icons/ai'

const AccordionContainer = styled.div`
  margin: 1rem 0;
  ${space}
  ${layout}
`

const AccordionContent = styled.div`
  position: absolute;
  z-index: 1;
  text-align: 'left';
  padding: .5rem;
  color: black;
  background-color: white;
  padding: 1rem;
`

const AccordionButton = styled.button`
  background-color: ${props => props.secondary ? 
    props.theme.secondary.main : props.theme.primary.main};
  outline: none;
  border: none;
  letter-spacing: .1rem;
  text-align: left;
  width: 100%;
  cursor: pointer;
  color: white;
  padding: 1rem;
`

const ArrowDownIcon = styled(AiOutlineArrowDown)`
  float: right;
`

const ArrowUpIcon = styled(AiOutlineArrowUp)`
  float: right;
`

const AccordionListItem = styled.li`
  padding: .5rem 0;
  font-size: .8rem;
`

const Accordion = ({
  title,
  children,
  ...rest
}) => {
  const [visible, setVisible] = useState(false)

  return (
    <AccordionContainer {...rest}>
      <AccordionButton onClick={(e) => setVisible(!visible)}> 
        {title} {visible ? <ArrowUpIcon/> : <ArrowDownIcon />}
      </AccordionButton>
      {visible &&
        <AccordionContent>
          {children}
        </AccordionContent>
      }
    </AccordionContainer>
  )
}

export default Accordion
export {
  AccordionListItem
}